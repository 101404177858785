



























































































import { ApiGetCourseDefaultOptionDto, ApiGetCustomerDto, ApiUpsertCourseParticipantDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { isVocationalSchool as useIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { required, validateMaxLength, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, Ref, ref } from "@vue/composition-api";
import { ActiveUser, MemberOrganization } from "./ParticipantsTable.vue";

const initialApplicant: () => ApiUpsertCourseParticipantDto = () => ({
  userId: 0,
  status: "",
  roleName: ParticipantRoles.Student,
  requiresElectronicInvoice: false,
  memberOrganizationId: null,
});

export default defineComponent({
  name: "UpsertParticipantModal",
  emits: ["updateParticipantsTable", "close"],
  components: { BaseModalForm },
  props: {
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    memberOrganizations: {
      type: Array as PropType<MemberOrganization[]>,
      required: true,
    },
    activeUsers: {
      type: Array as PropType<ActiveUser[]>,
      required: true,
    },
    courseDefaultOptions: {
      type: Object as PropType<ApiGetCourseDefaultOptionDto>,
      required: true,
    },
    companies: {
      type: Array as PropType<ApiGetCustomerDto[]>,
      required: true,
    },
    isCourseDone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();
    const applicantData: Ref<ApiUpsertCourseParticipantDto> = ref(initialApplicant());
    const isVocationalSchool = computed(() => useIsVocationalSchool(store.state.plans.studyplan.mainCourseId));
    const isInvoiceAndPurchaserEqual = ref(true);
    const useParticipantAsInvoice = ref(true);

    const courseParticipantStatuses = computed(() => {
      if (hasMemberOrgAccess) {
        return props.courseDefaultOptions.memberOrganizationParticipantStatuses;
      }
      if (isVocationalSchool.value) {
        return props.courseDefaultOptions.vocationalSchoolParticipantStatuses;
      }
      return props.courseDefaultOptions.participantStatuses;
    });

    const getActiveUserText = (item: ActiveUser) =>
      `${item.firstName} ${item.lastName} - ${item.email} - ${item.birthDate}`;

    onMounted(() => {
      if (!(props.type === ModalType.Edit)) {
        return;
      }

      // TODO switch to local data (and what if not loaded yet?)
      const {
        userId,
        status,
        roleName,
        requiresElectronicInvoice,
        statusComment,
        memberOrganizationId,
        invoiceRecipient,
        purchaser,
      } = store.state.courseParticipants.person;
      applicantData.value = {
        userId,
        status,
        roleName,
        requiresElectronicInvoice,
        statusComment,
        memberOrganizationId: memberOrganizationId || null,
        purchaserId: purchaser?.customerId || null,
        invoiceRecipientId: invoiceRecipient?.customerId || null,
      };

      if (applicantData.value.purchaserId !== applicantData.value.invoiceRecipientId) {
        isInvoiceAndPurchaserEqual.value = false;
      }

      if (applicantData.value.invoiceRecipientId && applicantData.value.invoiceRecipientId > 0) {
        useParticipantAsInvoice.value = false;
      }
    });

    const onSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        if (isInvoiceAndPurchaserEqual.value) {
          applicantData.value.invoiceRecipientId = applicantData.value.purchaserId;
        }
        await restrictedAccessApi.upsertCourseParticipant(
          +route.params.id,
          {
            ...applicantData.value,
            purchaserId: useParticipantAsInvoice.value ? null : applicantData.value.purchaserId,
            invoiceRecipientId: useParticipantAsInvoice.value ? null : applicantData.value.invoiceRecipientId,
          },
          applicantData.value.userId
        );
        openNotification(store, NotificationItemType.Success, "Bruker lagt til");
        emit("updateParticipantsTable");
        emit("close");
      });
    };

    return {
      ModalType,
      applicantData,
      validateNotEmpty,
      validateMaxLength,
      required,
      onSubmit,
      isVocationalSchool,
      hasMemberOrgAccess,
      courseParticipantStatuses,
      getActiveUserText,
      isInvoiceAndPurchaserEqual,
      useParticipantAsInvoice,
    };
  },
});
