



































































































import { api } from "@/api/api";
import {
  ApiGetCourseDefaultOptionDto,
  ApiGetCourseParticipantKursAdminDto,
  ApiGetCustomerDto,
  ApiGetCustomerPersonDto,
  ApiGetMemberOrganizationDto,
  ApiIdRankDto,
} from "@/api/generated/Api";
import UpsertParticipantModal from "@/components/course/details/UpsertParticipantModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CourseParticipantType } from "@/shared/enums/courseParticipantType.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { hasMemberOrgAccess, useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { formatRelative, formatDate, formatDateShort } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import isBefore from "date-fns/isBefore";
import { SortableEvent } from "sortablejs";
import { VSwitch } from "vuetify/lib";
import { mapGetters } from "vuex";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { getCustomerDtoRef } from "@/shared/helpers/courseHelpers";

export interface MemberOrganization {
  id: number | null;
  name?: string | null;
}

export interface ActiveUser {
  id: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  birthDate?: string | null;
}

export default defineComponent({
  name: "ParticipantsTable",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    UpsertParticipantModal,
    BaseModal,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();
    const showModal = ref(false);
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const courseStudents = ref<ApiGetCourseParticipantKursAdminDto[]>([]);
    const courseDefaultOptions = ref<ApiGetCourseDefaultOptionDto>();
    const contacts = ref<(ApiGetCustomerDto | ApiGetCustomerPersonDto)[]>([]);
    const companies = ref<ApiGetCustomerDto[]>([]);

    const canEditParticipant = () => {
      if (!hasMemberOrgAccess || !props.course.enrollmentDeadline) {
        return true;
      }

      return isBefore(new Date(props.course.enrollmentDeadline), new Date());
    };

    const ranking = computed(
      () => (item: ApiGetCourseParticipantKursAdminDto) => courseStudents.value.indexOf(item) + 1
    );

    const loadMemberOrganizations = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        memberOrganizations.value = (await restrictedAccessApi.getMemberOrganizations()).data.sort(
          localeSortByProperty("name")
        );
      });
    };

    const activeUsers = computed(() => {
      const filteredActiveUsers = contacts.value.filter((currentUser) => currentUser.isActive);
      return filteredActiveUsers.reduce<ActiveUser[]>(
        (previous, current: ApiGetCustomerDto | ApiGetCustomerPersonDto) => {
          const currentRef = getCustomerDtoRef(current);
          const activeUser = {
            id: current.id,
            firstName: currentRef.firstName,
            lastName: currentRef.lastName,
            email: current.email,
            birthDate: formatDate(currentRef.birthDate),
          };
          return [...previous, activeUser];
        },
        []
      );
    });

    const orderedMemberOrganizations = computed(() => {
      const activeMemberOrganizations = memberOrganizations.value.filter(
        (memberOrganization) => memberOrganization.isActive
      );

      // Parent/child lookup map
      // { [parentId]: MemberOrganization[] }
      const parentMemberOrganizationMap = activeMemberOrganizations.reduce<Record<number, MemberOrganization[]>>(
        (previous, { parentId, id, name }) => {
          if (parentId === null || parentId === undefined) {
            return previous;
          }
          const parentOrganization = { id, name };
          const childOrganizations = previous[parentId] ?? [];
          // lookup map with parentId as index which contains child object
          return {
            ...previous,
            [parentId]: [...childOrganizations, parentOrganization],
          };
        },
        {}
      );

      const activeParentMemberOrganizations = activeMemberOrganizations.filter(
        (memberOrganization) => !memberOrganization.parentId || memberOrganization.parentId === 1
      );

      // Ordered parent/child list of member organizations
      // [ parent_1, child_1_1, child_1_2, child_1_3, parent_2, child_2_1, ...]
      const orderedMemberOrganizations = activeParentMemberOrganizations.reduce<MemberOrganization[]>(
        (previous, { id, name }) => {
          const parentOrganization = { id, name };
          const subOrganizations = parentMemberOrganizationMap[id] ?? [];
          // map parent then add child by searching on lookup map
          return [...previous, parentOrganization, ...subOrganizations];
        },
        []
      );

      const noMemberOrganization = { id: null, name: "Ingen medlemsorganisasjon" };
      return [noMemberOrganization, ...orderedMemberOrganizations];
    });

    const sorted = (event: SortableEvent) => {
      const { newIndex, oldIndex } = event;
      if (newIndex === undefined || oldIndex === undefined) {
        return;
      }
      const element = courseStudents.value[oldIndex];
      courseStudents.value.splice(oldIndex, 1);
      courseStudents.value.splice(newIndex, 0, element);
    };

    const updateParticipantRank = async (courseParticipants: ApiGetCourseParticipantKursAdminDto[]) => {
      const studentsRanked: ApiIdRankDto[] = courseParticipants.map((student, index) => ({
        userId: student.userId,
        rank: index,
      }));
      await api.course.updateCourseParticipantRanksAsync(+route.params.id, studentsRanked);
      openNotification(store, NotificationItemType.Success, "Rangering oppdatert");
    };

    const getMemberOrganizationNameById = (id?: number) => {
      if (id === undefined) {
        return "Ingen medlemsorganisasjon";
      }
      return memberOrganizations.value?.find((office) => office.id === id)?.name || "Ukjent navn";
    };

    const getDefaultOptions = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const defaultOptions = (await restrictedAccessApi.getDefaultOptions()).data;
        courseDefaultOptions.value = defaultOptions;
      });
    };

    const getContacts = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const customerPersons = (await restrictedAccessApi.getCustomerPersons()).data;
        contacts.value = customerPersons;
      });
    };

    const getCompanies = async () => {
      if (hasMemberOrgAccess) {
        return;
      }
      companies.value = (await api.customer.getCustomerOrganizationsAsync()).data;
    };

    const getCourseParticipants = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const students = (
          await restrictedAccessApi.getCourseParticipants(+route.params.id, {
            RoleNames: [CourseParticipantType.Student],
            IsActive: true,
          })
        ).data;
        courseStudents.value = students;
      });
    };

    const closeModal = () => {
      showModal.value = false;
      loadMemberOrganizations();
    };

    onMounted(async () => {
      await Promise.all([
        loadMemberOrganizations(),
        getCourseParticipants(),
        getDefaultOptions(),
        getContacts(),
        getCompanies(),
      ]);
    });

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
      hasMemberOrgAccess,
      sorted,
      orderedMemberOrganizations,
      getMemberOrganizationNameById,
      courseStudents,
      showModal,
      ranking,
      closeModal,
      getCourseParticipants,
      courseDefaultOptions,
      activeUsers,
      updateParticipantRank,
      companies,
      isCourseDone: computed(() => props.course.status === CourseStatus.Closed),
      canEditParticipant: canEditParticipant(),
      formatDateShort,
      editParticipantButtonLabel: computed(() =>
        !canEditParticipant()
          ? "Kan ikke redigere deltaker før påmeldingsfrist er gått ut"
          : isVocationalSchool(store.state.plans.studyplan.mainCourseId)
          ? "Rediger student"
          : "Rediger deltaker"
      ),
    };
  },
  data() {
    return {
      modalHeadline: "",
      modalType: "",
      search: "",
    };
  },
  computed: {
    ...mapGetters("plans", ["getStudy"]),
    filter() {
      return [
        {
          component: VSwitch,
          value: "status",
          staticClass: "mx-3 pa-0",
          default: false,
          attrs: {
            label: "Vis venteliste",
            inset: true,
            hideDetails: true,
          },
          apply: (value: any, model: any) => (!model && value != null) || model,
        },
      ];
    },
    headers(props: any) {
      return [
        { text: "Rang.", value: "rank" },
        { text: "Navn", value: "userFullName" },
        { text: "Medlemsorganisasjon", value: "memberOrganizationId" },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Søknads metode",
          value: "appliedViaForm",
        },
        {
          text: "Bestiller",
          value: "purchaser.name",
        },
        {
          text: "Fakturamottaker",
          value: "invoiceRecipient.name",
        },
        props.course.plan.registerHours
          ? {
              text: "Oppmøteprosent",
              value: "currentAttendancePercentage",
            }
          : {},
        {
          text: "Oppdatert",
          value: "updated",
        },
        { text: "Handlinger", value: "actions", sortable: false },
      ];
    },
  },

  methods: {
    formatRelative,
    newParticipant() {
      this.$store.commit("courseParticipants/SET_PERSON", {});
      this.showModal = true;
      this.modalHeadline = `Legg til ${this.getHeadLineType((this as any).getStudy.mainCourseId)}`;
      this.modalType = "add";
    },
    editParticipant(value: any) {
      this.$store.commit("courseParticipants/SET_PERSON", value);
      this.showModal = true;
      this.modalHeadline = `Rediger ${this.getHeadLineType((this as any).getStudy.mainCourseId)}`;
      this.modalType = !this.isCourseDone ? "edit" : "display";
    },
    onepager(value: ApiGetCourseParticipantKursAdminDto) {
      this.$store.commit("courseParticipants/SET_PERSON", value);
      this.$router.push({
        path: `/kurset/${this.$route.params.id}/participants/${value.userId}`,
        query: { appliedViaForm: value.appliedViaForm.toString() },
      });
    },
    getHeadLineType(value: number) {
      // TODO : Add Enum for checking mainCourseId
      return isVocationalSchool(value) ? "Studenter" : "Deltakere";
    },
  },
});
